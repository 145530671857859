import React, { useEffect, useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import clsx from "clsx";
import { Box, Button, Typography, Grid, withStyles } from "@material-ui/core";
import { Container, Hero } from "@hyperobjekt/material-ui-website";
import Header from "gatsby-theme-hypersite/header/header";
import { default as Layout } from "gatsby-theme-hypersite/layout";
import { getTimeSince } from "../utils";
import { Feed } from "../components";
import team from "../../content/about/team.json";

const TeamPage = ({ classes, hero, data, ...props }) => {
	const { root, container, content, imageWrapper, image } = classes;

	return (
		<Layout {...props}>
			<div className={clsx(classes.root)}>
				<Container>
					{team.categories?.map((cat, i) => (
						<Grid container className={classes.category} key={i}>
							<Grid item xs={12} lg={5}>
								<Typography component="h2" variant="h3" gutterBottom>
									{cat.name}
								</Typography>
							</Grid>
							<Grid item xs={12} lg={7}>
								<Grid container spacing={4}>
									{cat.members.map((m, i) => (
										<Grid
											item
											xs={12}
											sm={6}
											className={classes.member}
											key={i}
										>
											<Typography className={classes.name}>{m.name}</Typography>
											<Typography className={classes.title}>
												{m.title}
											</Typography>
										</Grid>
									))}
								</Grid>
							</Grid>
						</Grid>
					))}
				</Container>
			</div>
		</Layout>
	);
};

TeamPage.defaultProps = {};

TeamPage.propTypes = {};

export default withStyles(theme => ({
	root: {
		margin: theme.spacing(6, "auto")
	},
	category: {
		margin: theme.spacing(6, 0),
		"& .MuiTypography-h3": {
			color: theme.palette.blue.header
		}
	},
	member: {
		// margin: theme.spacing(1, 0),
	},
	name: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: theme.typography.body.semibold.fontWeight
	},
	roles: {}
}))(TeamPage);

// export const pageQuery = graphql`
// 	{
// 		allMdx(
// 			sort: {
// 				fields: frontmatter___date,
// 				order: DESC
// 			}
// 			filter: {
// 				frontmatter: {
// 					draft: {ne: true},
// 					template: {eq: "report"}
// 				}
// 			}
// 		) {
// 			nodes {
// 				slug
// 				frontmatter {
// 					date
// 					name
// 				}
// 			}
// 		}
// 	}
// `;
